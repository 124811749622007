import { defineCustomElement, getCurrentInstance } from 'vue';

import utils from 'o365.modules.utils.js';
import translate from 'o365.modules.translate.ts';
import { getLibUrl } from 'o365.modules.helpers.js';
import {cdnBaseUrl} from 'o365.modules.helpers.js';


/**
 * Assign global properties to custom vue web component  
 * Must be called inside `setup` of the root instance
 */
export function useAppGlobals() {
    const instance = getCurrentInstance();
    instance.appContext.config.globalProperties.$format = utils.format;
    instance.appContext.config.globalProperties.$formatDate = utils.formatDate;
    instance.appContext.config.globalProperties.$formatNumber = utils.formatNumber;
    instance.appContext.config.globalProperties.$t = translate;

    instance.appContext.components = instance.type.components;
}

/**
 * Use Vue plugin inside a custom element
 * Must be called inside `setup` of the root instance
 */
export function usePlugin(plugin: { install: Function }) {
    const instance = getCurrentInstance();
    const ceApp = {
        config: instance.appContext.config,
        component: (key: string, def: any) => {
            if (!instance.appContext.components) { instance.appContext.components = {}; }
            instance.appContext.components[key] = def
        },
        directive: (key: string, def: any) => {
            if (!instance.appContext.directives) { instance.appContext.directives = {}; }
            instance.appContext.directives[key] = def
        },
        provide: (key: string, def: any) => {
            if (!instance.appContext.provides) { instance.appContext.provides = {}; }
            instance.appContext.provides[key] = def
        },
    };

    plugin.install(ceApp);
};

/**
 * Define component as a custom element
 */
export async function registerAsCustomElement(tag: string, Component: any, addStyles = true) {
    const alreadyExists = customElements.get(tag);
    if (alreadyExists) { return; }

    if (addStyles) {
        const styles = [
            `@import url("${cdnBaseUrl}/bootstrap/icons/bootstrap-icons.css")`,
            `@import url("${cdnBaseUrl}/bootstrap/v5.3.0-alpha3/css/bootstrap.min.css")`,
            `@import url("${await getLibUrl('o365.layout.css')}")`,
            `@import url("${await getLibUrl('o365.controls.DataGrid.scss')}")`,
            ':host { display: contents; }'
        ];

        document.head.querySelectorAll('style[type="text/css"]').forEach((styleEl: HTMLElement) => {
            styles.push(styleEl.innerText);
        });

        Component.styles = styles;
    }

    const CustomElement = defineCustomElement(Component);

    customElements.define(tag, CustomElement);

}
